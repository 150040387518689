import React, {useCallback} from 'react';
import {getMediaUrl} from '@wix/wixstores-client-core/dist/es/src/media/mediaService';
import {SocialVendor} from '../../constants';
import {ProvidedGlobalProps, withGlobalProps} from '../../providers/globalPropsProvider';
import {
  FacebookSocialNetwork,
  PinterestSocialNetwork,
  SocialShareService,
  TwitterSocialNetwork,
  WhatsappSocialNetwork,
} from '@wix/wixstores-client-storefront-sdk/dist/es/src/services/ProductSocialShare/SocialShareService';
import style from './ProductSocial.scss';
import {getDefaultProductMedia, shouldRenderVendorFromStyledParams} from './ProductSocialUtils';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {ProductSocialOld} from './ProductSocialOld';
import {SocialBar} from 'wix-ui-tpa';
import {Facebook, Pinterest, Whatsapp, XCorp} from '@wix/wix-ui-icons-common/on-stage';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {SPECS} from '../../specs';
import {pathgen} from 'object-path-generator';

export interface ProductSocialProps extends ProvidedGlobalProps, IProvidedTranslationProps {
  isLiveSiteMode?: boolean;
}

export const ProductSocialDataHooks = pathgen<{
  SocialSharing: string;
  SocialIcon(type: SocialVendor): string;
}>('ProductSocialDataHooks');

const socialNetworks = {
  [SocialVendor.Facebook]: FacebookSocialNetwork,
  [SocialVendor.Pinterest]: PinterestSocialNetwork,
  [SocialVendor.WhatsApp]: WhatsappSocialNetwork,
  [SocialVendor.Twitter]: TwitterSocialNetwork,
};

const socialIconByVendor = {
  [SocialVendor.Facebook]: <Facebook style={{color: '#107FEA'}} />,
  [SocialVendor.Pinterest]: <Pinterest style={{color: '#E81627'}} />,
  [SocialVendor.WhatsApp]: <Whatsapp style={{color: '#1BC149'}} />,
  [SocialVendor.Twitter]: <XCorp style={{color: '#000000'}} />,
};

const ProductSocialInternal: React.FC<ProductSocialProps> = ({globals, t}) => {
  const socialShareLinkByVendor = (shareType: SocialVendor) => {
    const {product, productUrl} = globals;
    const vendor = new socialNetworks[shareType]();
    const productImage = getDefaultProductMedia(product);
    const options = {asJpg: false, isSEOBot: true};
    const imageUrl = getMediaUrl(productImage, productImage, options);
    return new SocialShareService(vendor).getSocialNetworkUrl({
      title: product.name,
      url: productUrl,
      imageUrl,
    });
  };

  const onClick = (socialVendor: SocialVendor) => {
    globals.socialSharing.onClick({socialButton: socialVendor});
    const socialShareUrl = socialShareLinkByVendor(socialVendor);

    window.open(socialShareUrl, '_blank', 'width=700, height=600, top=300, left=300, chrome=yes, centerscreen');
  };

  const VendorSocialIcon = ({vendor}: {vendor: SocialVendor}) => {
    const memoizedOnClick = useCallback(() => onClick(vendor), [vendor]);
    const ariaLabel = vendor === SocialVendor.Twitter ? t('SHARE_TITLE_X') : t(`SHARE_TITLE_${vendor.toUpperCase()}`);
    return (
      <SocialBar.Icon
        data-hook={ProductSocialDataHooks.SocialIcon(vendor)}
        className={style.icon}
        aria-label={ariaLabel}
        onClick={memoizedOnClick}
        icon={socialIconByVendor[vendor]}
      />
    );
  };

  return (
    <SocialBar data-hook={ProductSocialDataHooks.SocialSharing()} className={style.root}>
      {Object.keys(socialNetworks)
        .filter((vendor: SocialVendor) => shouldRenderVendorFromStyledParams(vendor, {globals}))
        .map((vendor: SocialVendor) => (
          <VendorSocialIcon key={`social-sharing-${vendor}`} vendor={vendor} />
        ))}
    </SocialBar>
  );
};

const ProductSocialExperiment: React.FC<ProductSocialProps> = (props) => {
  const {experiments} = useExperiments();
  if (experiments.enabled(SPECS.ShouldProductPageUseNewSocialIcons)) {
    return <ProductSocialInternal {...props} />;
  } else {
    return <ProductSocialOld {...props} />;
  }
};

export const ProductSocial: React.FC<ProductSocialProps> = withGlobalProps(
  withTranslations('globals.texts')(ProductSocialExperiment)
);
